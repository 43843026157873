<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="교재 출고 현황"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">기간</th>
                                <td>
                                    <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inblock"></carrot-date-picker> 
                                    ~ 
                                    <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inblock"></carrot-date-picker>
                                </td>
                                <th width="180">교재 구분</th>
                                <td>
                                    <select v-model="bbs.state" class="w-300px">
                                        <option value="">전체</option>
                                        <option value="외부">외부교재</option>
                                        <option value="내부">내부교재</option>
                                        <option value="강사">강사용 교재</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th width="180">팀</th>
                                <td colspan="3">
                                    <carrot-team idx_office="99999" v-model="bbs.team" class="w-300px"></carrot-team>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button @click="bbs.doSearch" class="btn-default float-right mt-10">검색</button>
                    <div class="clear"></div>
                </div>

                <div v-if="bbs.isLoaded==true">
                    <div class="mt-50 mb-20">
                        <div class="block-title float-left">전체 출고현황</div>
                        <button @click="view.downExcel" class="btn-default float-right h-30px">엑셀 다운로드</button>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col span="7">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>총 수량(권)</th>
                                <th>외부교재(권)</th>
                                <th>내부교재(권)</th>
                                <th>유상(권)</th>
                                <th>무상(권)</th>
                                <th>강사용(권)</th>
                                <th>금액(원)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ bbs.total.cnt_total }}</td>
                                <td>{{ bbs.total.cnt_inner }}</td>
                                <td>{{ bbs.total.cnt_outer }}</td>
                                <td>{{ bbs.total.cnt_paid }}</td>
                                <td>{{ bbs.total.cnt_unpaid }}</td>
                                <td>{{ bbs.total.cnt_tutor }}</td>
                                <td>{{ Number(bbs.total.price).toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="block-title mt-50 mb-20">외부교재 출고현황</div>
                    <table class="table-col">
                        <colgroup>
                            <col span="6">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>외부교재 총 수량(권)</th>
                                <th>외부교재(권)</th>
                                <th>유상(권)</th>
                                <th>무상(권)</th>
                                <th>강사용(권)</th>
                                <th>금액(원)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ bbs.outer.cnt_total }}</td>
                                <td>{{ bbs.outer.cnt_outer }}</td>
                                <td>{{ bbs.outer.cnt_paid }}</td>
                                <td>{{ bbs.outer.cnt_unpaid }}</td>
                                <td>{{ bbs.outer.cnt_tutor }}</td>
                                <td>{{ Number(bbs.outer.price).toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="block-title mt-50 mb-20">내부교재 출고현황</div>
                    <table class="table-col">
                        <colgroup>
                            <col span="6">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>내부교재 총 수량(권)</th>
                                <th>내부교재(권)</th>
                                <th>유상(권)</th>
                                <th>무상(권)</th>
                                <th>강사용(권)</th>
                                <th>금액(원)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ bbs.inner.cnt_total }}</td>
                                <td>{{ bbs.inner.cnt_inner }}</td>
                                <td>{{ bbs.inner.cnt_paid }}</td>
                                <td>{{ bbs.inner.cnt_unpaid }}</td>
                                <td>{{ bbs.inner.cnt_tutor }}</td>
                                <td>{{ Number(bbs.inner.price).toLocaleString() }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="block-title mt-50 mb-20">팀 별 전체 출고현황</div>
                    <table class="table-col">
                        <colgroup>
                            <col span="8">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>팀</th>
                                <th>총 수량(권)</th>
                                <th>외부교재(권)</th>
                                <th>내부교재(권)</th>
                                <th>유상(권)</th>
                                <th>무상(권)</th>
                                <th>강사용(권)</th>
                                <th>금액(원)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.teams.total" :key="i">
                                <td class="txt-ellipsis">{{ irow.teamname }}</td>
                                <td>{{ irow.cnt_total }}</td>
                                <td>{{ irow.cnt_inner }}</td>
                                <td>{{ irow.cnt_outer }}</td>
                                <td>{{ irow.cnt_paid }}</td>
                                <td>{{ irow.cnt_unpaid }}</td>
                                <td>{{ irow.cnt_tutor }}</td>
                                <td>{{ Number(irow.price).toLocaleString() }}</td>
                            </tr>
                            <tr v-if="bbs.teams.total.length==0">
                                <td colspan="7">조건을 만족하는 팀이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="block-title mt-50 mb-20">팀 별 내부교재 출고현황</div>
                    <table class="table-col">
                        <colgroup>
                            <col span="7">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>팀</th>
                                <th>총 수량(권)</th>
                                <th>외부교재(권)</th>
                                <th>내부교재(권)</th>
                                <th>유상(권)</th>
                                <th>무상(권)</th>
                                <th>금액(원)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.teams.inner" :key="i">
                                <td>{{ irow.teamname }}</td>
                                <td>{{ irow.cnt_total }}</td>
                                <td>{{ irow.cnt_inner }}</td>
                                <td>{{ irow.cnt_outer }}</td>
                                <td>{{ irow.cnt_paid }}</td>
                                <td>{{ irow.cnt_unpaid }}</td>
                                <td>{{ Number(irow.price).toLocaleString() }}</td>
                            </tr>
                            <tr v-if="bbs.teams.inner.length==0">
                                <td colspan="7">조건을 만족하는 팀이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="block-title mt-50 mb-20">강사용 교재 전체 출고현황</div>
                    <table class="table-col">
                        <colgroup>
                            <col span="7">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>지급 완료(권)</th>
                                <th>외부교재(권)</th>
                                <th>내부교재(권)</th>
                                <th>Deposit(권)</th>
                                <th>Refund(권)</th>
                                <th>처리불가(권)</th>
                                <th>미 반납(권)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ bbs.tutor.total.cnt_total }}</td>
                                <td>{{ bbs.tutor.total.cnt_outer }}</td>
                                <td>{{ bbs.tutor.total.cnt_inner }}</td>
                                <td>{{ bbs.tutor.total.cnt_deposit }}</td>
                                <td>{{ bbs.tutor.total.cnt_refund }}</td>
                                <td>{{ bbs.tutor.total.cnt_cancel }}</td>
                                <td>{{ bbs.tutor.total.cnt_over }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="block-title mt-50 mb-20">팀 별 강사용 교재 출고현황</div>
                    <table class="table-col">
                        <colgroup>
                            <col span="8">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>팀</th>
                                <th>지급 완료(권)</th>
                                <th>외부교재(권)</th>
                                <th>내부교재(권)</th>
                                <th>Deposit(권)</th>
                                <th>Refund(권)</th>
                                <th>처리불가(권)</th>
                                <th>미 반납(권)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.tutor.teams" :key="i">
                                <td>{{ irow.teamname }}</td>
                                <td>{{ irow.cnt_total }}</td>
                                <td>{{ irow.cnt_outer }}</td>
                                <td>{{ irow.cnt_inner }}</td>
                                <td>{{ irow.cnt_deposit }}</td>
                                <td>{{ irow.cnt_refund }}</td>
                                <td>{{ irow.cnt_cancel }}</td>
                                <td>{{ irow.cnt_over }}</td>
                            </tr>
                            <tr v-if="bbs.tutor.teams.length==0">
                                <td colspan="8">조건을 만족하는 팀이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'


export default {
    layout:"ResourceManagement",
    components: {
        CarrotDatePicker,
        CarrotTeam
    },
    setup() {
        const toast = useToast()

        const bbs = reactive({
            isLoaded : false,

            sdate : "",
            edate : "",
            state : "",
            team  : 0,

            total : {},
            outer : {},
            inner : {},
            teams : {},
            tutor : {},

            downExcel : () => {
                location.href = "https://intranet.mxm.kr/excel/textbook_statistics_list?sdate=" + bbs.sdate + "&edate=" + bbs.edate + "&state=" + bbs.state + "&team=" + bbs.team;
            },
            
            doSearch : () => {
                if( bbs.isLoaded == false ) return;
                let params = {
                    sdate : bbs.sdate,
                    edate : bbs.edate,
                    state : bbs.state,
                    team  : bbs.team
                };

                bbs.isLoaded = false;
                axios.get("/rest/resourceManagement/getBookStatisticsList", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.total = res.data.total;
                        bbs.outer = res.data.outer;
                        bbs.inner = res.data.inner;
                        bbs.teams = res.data.teams;
                        bbs.tutor = res.data.tutor;

                        console.log(bbs.tutor.total);

                        bbs.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            bbs.isLoaded = true;

            let nd = new Date();
            let sd = new Date(nd.getFullYear(), nd.getMonth(), 1);
            bbs.sdate = [ sd.getFullYear(), (sd.getMonth()>8?sd.getMonth()+1:'0'+(sd.getMonth()+1)), (sd.getDate()>9?sd.getDate():'0'+sd.getDate()) ].join('-');
            let ed = new Date(nd.getFullYear(), nd.getMonth()+1, 0);
            bbs.edate = [ ed.getFullYear(), (ed.getMonth()>8?ed.getMonth()+1:'0'+(ed.getMonth()+1)), (ed.getDate()>9?ed.getDate():'0'+ed.getDate()) ].join('-');

            bbs.doSearch();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>